import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import FeaturedProject from "../components/featured-project"
import {mapOrder} from "../functions/MapOrder"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const FeaturedProjectBlock = ({title, featuredProjects, order}) => {
  featuredProjects = order && order.length > 0 ? mapOrder(featuredProjects, order) : featuredProjects
  return (
    <div className="mb-8">
      { title !== "" && <h1 className="text-center mb-8">{title}</h1> }
      <div className="px-2 mb-8">
        <div className="flex -mx-2 flex-wrap">
            {featuredProjects.map((projectEdge, i) => (
              <FeaturedProject featuredProject={projectEdge.node} index={i} key={projectEdge.node.wordpress_id}/>
            ))}
        </div>
      </div>
    </div>
  )
}

FeaturedProjectBlock.propTypes = {
  title: PropTypes.string,
  featuredProjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.arrayOf(PropTypes.number),
}

FeaturedProjectBlock.defaultProps = {
  title: "",
  order: [],
}

export const query = graphql`
  fragment FeaturedProjectsFragment on WpProjectConnection {
    edges {
      node {
        databaseId
        ...FeaturedProjectFragment
      }
    }
  }
`

export default FeaturedProjectBlock
