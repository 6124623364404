import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import BackgroundImage from 'gatsby-background-image-es5'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const FeaturedProject = ({featuredProject, index}) => {
  var baseClass = "w-full sm:pl-24 sm:pr-24 lg:w-1/2 lg:pl-0 lg:pr-0 lg:odd:pr-12 lg:even:pl-12";
  var marginTop = baseClass;
  if(index + 1 === 2)
    marginTop += " mt-8 lg:mt-0";
  else if(index + 1 > 2)
    marginTop += " mt-8";
  return (
    <>          
      <div className={marginTop} key={featuredProject.wordpress_id}>
        <BackgroundImage
          fluid={featuredProject.featuredImage.node.localFile.childImageSharp.fluid} 
          key={featuredProject.featuredImage.node.localFile.childImageSharp.fluid.src}
        >        
          <a className="text-scporange hover:underline" href={featuredProject.projectDetails.scpProjectUrl}>
            <div className="h-64 w-full flex">
              <div className="bg-black h-full w-full opacity-50 absolute"/> 
              {/* Content for the featured project */}
              <div className="h-full w-full relative flex">
                <div className="mt-auto">
                  <h2 className="pb-2 pl-4">{featuredProject.title}</h2>
                </div>
              </div>
            </div>
          </a>
        </BackgroundImage>
      </div>
    </>
  )
}

FeaturedProject.propTypes = {
    featuredProject: PropTypes.object,
    index: PropTypes.number,
}

export const query = graphql`
  fragment FeaturedProjectFragment on WpProject {
    title
    featuredImage {
      node{
        localFile {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    ... on WpProject {
      projectDetails {
        scpProjectDescription
        scpProjectUrl
      }
    }
    # cmb2 {
    #   project_details_metabox {
    #     _SCP_Project_description
    #     _SCP_Project_url
    #   }
    # }
  }
`

export default FeaturedProject
